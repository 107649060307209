import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SidebarFooterLink, SidebarStyle } from "./Sidebar.style";
import Logo from "assets/icons/Logo";
import Burger from "assets/icons/Burger";
import { SideItems } from "./SideItems";
import { IPropsT, ISideItemsChildrenT, ISideItemsT } from "./Sidebar.types";
import Employee from "assets/icons/Employee";
import "react-pro-sidebar/dist/css/styles.css";

const Sidebar = ({ collapse, setCollapse }: IPropsT) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <SidebarStyle>
      <ProSidebar collapsed={collapse} breakPoint="md">
        <SidebarHeader>
          <div className="sidebar-header">
            <div className="d-flex align-items-center">
              {!collapse && <Logo />}
              <div
                className={`burger ${collapse ? "rotate-burger" : ""}`}
                onClick={() => setCollapse(!collapse)}
              >
                <Burger />
              </div>
            </div>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="circle">
            {SideItems.map((item: ISideItemsT) => {
              return item.children ? (
                <SubMenu
                  key={item.id}
                  title={t([`COMMON.${item.title}`])}
                  icon={item.icon}
                >
                  {item.children.map((children: ISideItemsChildrenT) => {
                    return (
                      <MenuItem
                        key={children.id}
                        className={`text-black`}
                        active={
                          location.pathname === children.path ||
                          !!children?.insidePaths?.find(
                            (path: string) =>
                              path === location.pathname ||
                              path === location.pathname.slice(0, -25)
                          )
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          if (location.pathname !== children.path) {
                            navigate(children.path);
                          }
                        }}
                      >
                        {t([`COMMON.${children.title}`])}
                      </MenuItem>
                    );
                  })}
                </SubMenu>
              ) : (
                <MenuItem
                  key={item.id}
                  className={`text-black`}
                  icon={<Employee />}
                  active={
                    location.pathname === item.path ||
                    !!item?.insidePaths?.find(
                      (path: string) =>
                        path === location.pathname ||
                        path === location.pathname.slice(0, -25)
                    )
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (location.pathname !== item.path) {
                      navigate(item.path);
                    }
                  }}
                >
                  {t([`COMMON.${item.title}`])}
                </MenuItem>
              );
            })}
          </Menu>
        </SidebarContent>
        <SidebarFooter className="sidebar-footer py-3">
          <SidebarFooterLink href="https://t.me/edutizim" target="_blank">
            {t("COMMON.EDU_SYSTEM")}
            {!collapse && "© 2020"}
          </SidebarFooterLink>
        </SidebarFooter>
      </ProSidebar>
    </SidebarStyle>
  );
};

export default Sidebar;
